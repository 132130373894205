import { getCurrentInstance } from "vue";

export default {
  props: {
    /**
     * Model value for v-model
     */
    modelValue: {},
    /**
     * Should a label be displayed
     * Can pass false to force hide the label
     */
    showLabel: {
      type: Boolean,
      default: true,
    },
    /**
     * Label for the input
     * Used for any type of input. For checkboxes, shows next to input
     */
    label: {
      type: [String, Boolean],
      default: false,
    },
    /**
     * Should label be injected as HTML
     * TODO: not fully implemented yet
     **/
    htmlLabel: {
      type: [Boolean],
      default: false,
    },
    labelInline: {
      type: [Boolean, String],
      default: false,
    },
    forceLabelInline: {
      type: [Boolean, String],
      default: false,
    },
    /**
     * Name for input
     */
    name: {
      type: [String, Boolean],
      default: false,
    },
    /**
     * Placeholder for the input/textarea
     */
    placeholder: {
      type: [String, Boolean],
      default: false,
    },
    /**
     * Type of input - checkbox radio select and textarea have special treatment
     * Other values are used as type of a regular input element
     */
    type: {
      type: [String, Boolean],
      default: "text",
    },
    /**
     * Size of the input
     * @values default, small, large
     **/
    size: {
      type: [String],
      default: "default",
    },
    /**
     * List of options. Relevant for select and radio.
     * For radio, each option will create a radio+own label
     *
     * Input must be array of objects, each having a value and a label property
     */
    list: {
      type: [Array],
      default: [],
    },
    /**
     * In case we are rendering a radio input (multiple radio inputs),
     * Should they be stacked visually? For horizontal forms, we get horizontal
     * radios unless this is true
     */
    radioStacked: {
      type: [String, Boolean],
      default: false,
    },
    /**
     * Class for the OUTER wrapper
     */
    class: {
      type: [String, Boolean],
      default: false,
    },
    /**
     * ID for the outer wrapper. Input id is handled by the component separately.
     */
    id: {
      type: [String, Boolean],
      default: false,
    },
    /**
     * Icon for the input. It is advisable not to use in a checkbox, radio, select, textarea
     * Used as class name, works with any icon in the framework
     */
    icon: {
      type: [Boolean, String],
      default: false,
    },
    /**
     * Icon to be position after the slot
     **/
    iconEnd: {
      type: [Boolean, String],
      default: false,
    },
    /**
     * Error message to show on field. If not exactly '' -> field is considered "with error"
     */
    errorMessage: {
      type: [String, Array, Boolean],
      default: "",
    },
    /**
     * Style of error display
     * @values 'full', 'slim',
     */
    errorDisplay: {
      type: [String],
      default: "full",
    },
    /**
     * Time in miliseconds within which a field, which had error and now does not, will be green
     */
    errorFixIndicationDuration: {
      type: Number,
      default: 1500,
    },
    /**
     * Used on form component. But we dont want that bound to the element itself, so we add this as a prop
     */
    contexts: {
      default() {
        return {};
      },
    },
    /**
     * Is field validation waiting (we use this for ui)
     */
    validation: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * Is validation in progress
     */
    validationPending: {
      type: Boolean,
      default: false,
    },
    /**
     * Should component render some margins automatically on wrapper
     */
    wrapperMargins: {
      type: Boolean,
      default: true,
    },
    /**
     * Should component render some margins automatically on wrapper
     */
    wrapperPadding: {
      type: Boolean,
      default: true,
    },
    /**
     * Size of the input. Can be false for no limit, string for UK class, or integer for px
     * @values false, 'large','medium','small','xsmall', [any integer]
     */
    inputSize: {
      type: [Boolean, String, Number],
      default: false,
    },
    /**
     * Class for the inner input element
     */
    controlsClass: {
      type: String,
      default: "",
    },
    /**
     * Class for the inner input element
     */
    innerClass: {
      type: String,
      default: "",
    },
    autoTranslate: {
      type: Boolean,
      default: true,
    },
    /**
     * Should we apply UI Framework classes to style this input
     */
    autoInputStyling: {
      type: Boolean,
      default: true,
    },
    /**
     * Position of errors
     * Available options: relative, absolute
     **/
    errorDisplayPosition: {
      type: String,
      default: "relative",
    },
    doValidations: {
      type: Boolean,
      default: false,
    },
    validationNamespace: {
      type: [String, Boolean],
      default: false,
    },
    wrapperStyle: {
      type: [Object],
      default: {},
    },
    theme: {
      type: String,
      default: "default",
    },
    themeStyle: {
      type: String,
      default: "default",
    },
    legend: {
      type: [Boolean, String],
      default: false,
    },
    legendIcon: {
      type: [Boolean, String],
      default: false,
    },
    legendParams: {
      type: Object,
      default: {},
    },
    tooltip: {
      type: [String, Boolean],
      default: false,
    },
  },
  inheritAttrs: false,
  data: function () {
    return {
      errorFixed: false,
    };
  },
  computed: {
    safeWrapperStyle() {
      if (typeof this.wrapperStyle !== "object" || !this.wrapperStyle) {
        return {};
      }

      return this.wrapperStyle;
    },
    value: {
      get() {
        let target = this.modelValue;
        // for non text/textarea inputs, return value untouched
        if (this.type === "checkbox" || this.type === "radio" || this.type === "select") {
          return target;
        }

        // for text/textarea, cast to string if false
        if (target === false) {
          return "";
        }
        // defer to returning the default value
        return target;
      },
      set(newValue) {
        /**
         * Updates model
         */
        this.$emit("field:changed", newValue);
        this.$emit("update:modelValue", newValue);
        return true;
      },
    },
    finalPlaceHolder() {
      let res = typeof this.placeholder === "string" ? this.placeholder : "";
      //   if (res !== '' &&this.isRequired) {
      //    res = res + ' *'
      //   }
      return res;
    },
    finalLabel() {
      let res = typeof this.label === "string" ? this.label : "";
      //   if (res !== '' && this.isRequired) {
      //     res = res + ' *'
      //    }
      return res;
    },
    showLabelFinal() {
      let forceHide = !this.showLabel;
      let hasLabel = typeof this.label === "string" && this.label !== "";

      return hasLabel && !forceHide;
    },
    inputStyle() {
      let styles = {};
      let propClass = this.innerClass.split(" ");

      if (this.inputSize && typeof this.inputSize === "number") {
        styles["width"] = this.inputSize + "px";
      }

      // if select and has icon -padding
      if (this.type === "select" && this.icon) {
        styles["padding-inline-start"] = "calc(var(--global-margin)*1.5)";
      }

      return styles;
    },
    inputClass() {
      let inputClass = [];
      let propClass = this.innerClass.split(" ");

      // error class
      if (this.hasError) {
        inputClass.push("uk-form-danger");
      }

      // error fixed indicator
      if (this.errorFixed) {
        inputClass.push("uk-form-success");
      }

      // skip additional styleing if so desired
      if (!this.autoInputStyling) {
        return [...inputClass, ...propClass];
      }

      // apply styiling
      if (this.inputSize && typeof this.inputSize === "string") {
        inputClass.push("uk-form-width-" + this.inputSize);
      }

      if (this.type === "checkbox") {
        inputClass.push("uk-checkbox");
        return inputClass;
      }

      if (this.type === "radio") {
        inputClass.push("uk-radio");
        return inputClass;
      }

      if (this.type === "textarea") {
        inputClass.push("uk-textarea");
        return inputClass;
      }

      // select and "input" can have size modifiers
      if (this.type === "select") {
        inputClass.push("uk-select");
      } else {
        inputClass.push("uk-input");
      }

      if (this.size === "large") {
        inputClass.push("uk-form-large");
      }

      if (this.size === "small") {
        inputClass.push("uk-form-small");
      }

      return [...inputClass, ...propClass];
    },
    labelClass() {
      return ["uk-form-label"];
    },
    wrapperClass() {
      let result = ["input-wrapper"];

      if (this.wrapperMargins) {
        result.push("uk-margin-bottom");
      }

      if (this.hasError) {
        result.push("input-wrapper-error");
      }

      result.push(this.class);
      return result;
    },
    finalControlsClass() {
      let result = [this.controlsClass];

      return result;
    },
    inputSubType() {
      if (this.type === "hidden") {
        return "hidden";
      }

      if (this.type === "checkbox") {
        return "checkbox";
      }
      if (this.type === "radio") {
        return "radio";
      }

      if (this.type === "select") {
        return "select";
      }

      if (this.type === "textarea") {
        return "textarea";
      }

      if (["text", "tel", "email", "number"].includes(this.type)) {
        return "input";
      }

      return "custom";
    },
    customInputComponent() {
      return utilities.ucFirst(this.type) + "Input";
    },
    fieldId() {
      return this.id || this.$.uid;
    },
    inputAttrs() {
      let inputAttrs = { ...this.$attrs, ...this.$props };
      // remove special cases that cause issues with vue or uikit
      delete inputAttrs.class;
      delete inputAttrs.id;
      delete inputAttrs.type;
      delete inputAttrs.size;
      delete inputAttrs.placeholder;
      delete inputAttrs.size;
      inputAttrs["class"] = this.innerClass || "";

      return inputAttrs;
    },
    hasError() {
      return this.errorMessage !== "" || this.errorMessage === false;
    },
    computedTooltip() {
      if (this.tooltip === false) {
        return false;
      }

      return {
        content: this.tooltip,
      };
    },
    showRequiredIndicator() {
      if (this.required) {
        return true;
      }

      if (!config.form.showRequiredIndicator) {
        return false;
      }

      // prevent double * when nested
      if (this.placeholder && this.placeholder.endsWith(" *")) {
        return false;
      }
      if (this.label && this.label.endsWith(" *")) {
        return false;
      }
      if (!this.validation || typeof this.validation !== "object") {
        return false;
      }

      if (this.validation.required) {
        return true;
      }

      if (this.validation.notEmpty) {
        return true;
      }
      return false;
    },
    showRequiredIndicatorInPlaceholder() {
      if (this.required) {
        return true;
      }

      if (!this.placeholder || this.placeholder === "") {
        return false;
      }

      if (!config.form.showRequiredIndicator) {
        return false;
      }

      // prevent double * when nested
      if (this.placeholder && this.placeholder.endsWith(" *")) {
        return false;
      }
      if (this.label && this.label.endsWith(" *")) {
        return false;
      }
      if (!this.validation || typeof this.validation !== "object") {
        return false;
      }

      if (this.validation.required) {
        return true;
      }

      if (this.validation.notEmpty) {
        return true;
      }
      return false;
    },
  },
  emits: ["update:modelValue", "field:changed"],
  methods: {
    ucFirst(val) {
      return utilities.ucFirst(val);
    },
    translateString(val) {
      if (this.autoTranslate) {
        return this.safeTranslate(val);
      } else {
        return val;
      }
    },
    focus() {
      try {
        // try custom focus behaviour
        this.$refs.formControl.focus();
      } catch (e) {
        try {
          // oh... lets try to catch their element and trigger a "normal" focus
          this.$refs.formControl.$el.focus();
        } catch (e) {
          // we did everything we can do
        }
      }
    },
  },
  watch: {
    hasError(hasError, hadError) {
      if (hadError && !hasError) {
        this.errorFixed = true;
      }
    },
    errorFixed() {
      if (this.validationPending) {
        this.errorFixed = false;
        return false;
      }

      let modelTarget = typeof this.value === "undefined" ? this.value : this.modelValue;
      if (modelTarget === null) {
        this.errorFixed = false;
        return;
      }

      clearTimeout(this.errorFixedTimeout);
      this.errorFixedTimeout = utilities.setClientTimeout(() => {
        this.errorFixed = false;
      }, this.errorFixIndicationDuration);
    },
  },
  created() {
    this.errorFixedTimeout = "";
  },
  mounted() {
    // this.v$.$touch();
  },
};
